<template>
  <div>
    <section class="section is-medium pt-3" id="agb">
      <div class="container">
        <div
          class="is-flex is-justify-content-space-between mb-6 why-do-i-need-custom-styles-for-this"
        >
          <div class="is-flex is-flex-wrap-wrap">
            <router-link
              class="button is-text"
              :to="'/' + $route.params.locale + '/'"
            >
              {{ $t("views.register.back") }}
            </router-link>
            <a
              class="button is-light"
              :href="`https://rep.guide/${$root.$i18n.locale}/login`"
              >{{ $t("views.register.signin") }}</a
            >
          </div>
          <div>
            <language-select verbose />
          </div>
        </div>
        <div class="content">
          <img
            :alt="$t('views.register.logo-alt')"
            src="../assets/RepGuideLogo.svg"
            width="200"
            class="is-block mx-auto"
          />
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.register.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />

          <div class="notification is-info">
            <div class="columns is-mobile">
              <div class="column is-narrow pl-4">
                <fa icon="exclamation" class="fa-2x icon" />
              </div>
              <div class="column">
                <p>{{ $t("views.register.info") }}</p>
              </div>
            </div>
          </div>

          <!-- MARK: CONTRACT ---------------------------------------------- -->
          <div class="columns mt-6">
            <div class="column is-narrow">
              <div
                class="is-flex is-justify-content-center is-align-items-center"
                style="
                  border-radius: 999px;
                  background: #fadace;
                  height: 60px;
                  width: 60px;
                "
              >
                <strong
                  class="is-size-4 is-family-monospace has-text-weight-bold has-text-primary"
                >
                  1
                </strong>
              </div>
            </div>

            <div class="column">
              <h2 class="title is-3 is-size-4-mobile my-2">
                {{ $t("views.register.contract.headline") }}
              </h2>

              <p class="is-size-5 has-text-grey mb-3">
                {{ $t("views.register.contract.description") }}
              </p>

              <div class="columns is-desktop mt-5">
                <div class="column" v-if="contractPartnersLoading">
                  <div class="columns is-desktop animate-pulse">
                    <div class="column" v-for="_ in Array(2)" :key="_">
                      <div
                        class="card is-shadowless has-background-white-ter"
                        style="border: 1px solid hsl(0, 0%, 86%); height: 28rem"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="column is-flex"
                  v-for="p in availablePricings"
                  :key="p.contract_key"
                >
                  <button
                    @click="pricing = p"
                    :class="[
                      'button py-4 px-5 is-fullwidth has-text-left is-align-items-flex-start is-flex-direction-column',
                      {
                        'please-let-me-use-tailwind':
                          pricing === p ||
                          selectedPartner.Pricings.length === 1,
                      },
                    ]"
                    style="height: unset; white-space: normal"
                  >
                    <div
                      class="level is-mobile mb-2 is-align-items-flex-start"
                      style="flex: 0 1 0%; width: 100%; gap: 12px"
                    >
                      <strong
                        class="title is-4 has-text-primary m-0 mt-1"
                        style="white-space: normal"
                      >
                        {{
                          $t(
                            `views.register.contract.types.${p.Pricing.contract_key}`
                          )
                        }}
                      </strong>
                      <span
                        :class="{
                          'has-background-white':
                            pricing !== p &&
                            selectedPartner.Pricings.length > 1,
                        }"
                        style="
                          width: 40px;
                          height: 40px;
                          border-radius: 999px;
                          display: grid;
                          place-items: center;
                          background: #fadace;
                          flex-shrink: 0;
                        "
                      >
                        <fa
                          v-if="
                            pricing === p ||
                            selectedPartner.Pricings.length === 1
                          "
                          icon="check"
                          class="has-text-primary is-size-5"
                        />
                      </span>
                    </div>

                    <section
                      class="mt-1 mb-4 px-3 py-2 has-background-white-bis"
                      style="
                        border: 1px solid hsl(0, 0%, 86%);
                        border-radius: 4px;
                        width: 100%;
                      "
                    >
                      <div v-for="s in getPricingStatistics(p)" :key="s.title">
                        <strong class="is-size-5 has-text-weight-semibold">
                          {{ s.count }}
                        </strong>
                        <span class="has-text-grey">
                          {{ s.title }}
                        </span>
                      </div>
                    </section>

                    <strong>
                      {{ $t("views.register.contract.features.headline") }}
                    </strong>
                    <ul
                      class="is-flex-grow-1 mt-1 ml-1 has-text-grey"
                      style="list-style: none; width: 100%"
                    >
                      <li
                        v-for="feature in getPricingFeatures()"
                        :key="feature.title"
                        class="is-flex is-align-items-start is-relative contract-bullet"
                        style="gap: 0.25rem"
                      >
                        <fa
                          :icon="feature.icon"
                          class="fa-fw has-text-primary mr-1"
                        />
                        <span style="flex: 1 1 0%">
                          {{ feature.title }}
                        </span>
                        <template v-if="feature.desc">
                          <fa
                            v-if="feature.desc"
                            icon="info-circle"
                            class="fa-fw has-text-grey-lighter mt-1"
                          />
                          <div class="contract-bullet-tooltip p-3">
                            {{ feature.desc }}
                          </div>
                        </template>
                      </li>
                    </ul>

                    <strong>
                      {{ $t("views.register.contract.benefits.headline") }}
                    </strong>
                    <ul
                      class="is-flex-grow-1 mt-1 ml-1 has-text-grey"
                      style="list-style: none; width: 100%"
                    >
                      <li
                        v-for="benefit in getPricingBenefits(p)"
                        :key="benefit.title"
                        class="is-flex is-align-items-start is-relative contract-bullet"
                        style="gap: 0.25rem"
                      >
                        <fa
                          :icon="benefit.icon"
                          class="fa-fw has-text-primary mt-1"
                        />
                        <span style="flex: 1 1 0%">
                          {{ benefit.title }}
                        </span>
                        <template v-if="benefit.desc">
                          <fa
                            v-if="benefit.desc"
                            icon="info-circle"
                            class="fa-fw has-text-grey-lighter mt-1"
                          />
                          <div class="contract-bullet-tooltip p-3">
                            {{ benefit.desc }}
                          </div>
                        </template>
                      </li>
                    </ul>

                    <div v-for="c in getPricingConditions(p)" :key="c.title">
                      <strong :class="{ 'has-text-primary': c.highlight }">
                        {{ c.title }}
                        <small v-if="c.meta"> ({{ c.meta }})</small>
                      </strong>
                      <ul class="is-flex-grow-1 mt-1 mb-4 ml-5 has-text-grey">
                        <li
                          v-for="li in c.description"
                          :key="li"
                          class="highlight-links pl-1 ml-1"
                          v-html="li"
                        />
                      </ul>
                    </div>

                    <div
                      class="m-0 is-narrow p-0 is-align-self-flex-end has-text-right mt-3"
                    >
                      <strong
                        class="has-text-primary is-size-3"
                        style="line-height: 0"
                      >
                        {{ getInvoiceItemDisplay(p.Pricing.InvoiceItem) }}
                      </strong>
                      <br />
                      <span class="has-text-grey" style="line-height: 0">
                        {{ $t("views.register.contract.per-month") }}
                      </span>
                    </div>

                    <div
                      v-if="availablePricings.length > 1"
                      class="is-flex mt-4 mb-2"
                      style="width: 100%"
                    >
                      <button
                        v-if="pricing === p"
                        class="button is-fullwidth is-primary"
                      >
                        {{ $t("views.register.contract.selected") }}
                      </button>
                      <button v-else class="button is-fullwidth">
                        {{ $t("views.register.contract.select") }}
                      </button>
                    </div>
                  </button>
                </div>
              </div>

              <i18n
                path="views.register.contract.intro.footnote"
                tag="p"
                class="has-text-grey mt-2"
              >
                <a
                  :href="
                    generatePartnerLink(
                      selectedPartner,
                      selectedPartner && selectedPartner.terms
                        ? selectedPartner.terms
                        : '/terms'
                    )
                  "
                  target="_blank"
                  >{{ $t("views.register.contract.intro.terms") }}</a
                >
              </i18n>
            </div>
          </div>

          <!-- MARK: ACCOUNT ----------------------------------------------- -->
          <div class="columns mt-6">
            <div class="column is-narrow">
              <div
                class="is-flex is-justify-content-center is-align-items-center"
                style="
                  border-radius: 999px;
                  background: #fadace;
                  height: 60px;
                  width: 60px;
                "
              >
                <strong
                  class="is-size-4 is-family-monospace has-text-weight-bold has-text-primary"
                >
                  2
                </strong>
              </div>
            </div>

            <div class="column">
              <h2 class="title is-3 is-size-4-mobile my-2">
                {{ $t("views.register.account.headline") }}
              </h2>
              <p class="is-size-5 has-text-grey mb-3">
                {{ $t("views.register.account.description") }}
              </p>

              <div class="columns is-relative">
                <div class="column is-full-tablet is-8-desktop is-7-widescreen">
                  <div
                    class="card py-5 px-4 mt-5 is-relative is-clipped is-bordered"
                    :inert="
                      !selectedPartner ||
                      (selectedPartner.Pricings.length > 1 && !pricing)
                    "
                    style="border: 1px solid hsl(0, 0%, 86%)"
                  >
                    <div class="columns">
                      <div class="column is-one-third">
                        <p class="mb-2 has-text-weight-semibold">
                          {{
                            $t(
                              "views.register.account.form.salutation.headline"
                            )
                          }}
                          <span v-if="form.salutation !== 'other'">*</span>
                        </p>
                        <div class="select" style="width: 100%">
                          <select
                            v-model="form.salutation"
                            @change="error = false"
                            :class="[
                              'formelement',
                              {
                                'is-danger':
                                  showValidation && !form.salutation.trim(),
                              },
                            ]"
                            :placeholder="
                              $t(
                                'views.register.account.form.salutation.headline'
                              )
                            "
                            autocomplete="honorific-prefix"
                            style="width: 100%"
                          >
                            <option
                              v-for="salutation in salutations"
                              :key="salutation.value"
                              :value="salutation.value"
                            >
                              {{ salutation.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="column is-two-thirds">
                        <template v-if="form.salutation === 'other'">
                          <p class="mb-2 has-text-weight-semibold">
                            {{
                              $t(
                                "views.register.account.form.salutation.headline-other"
                              )
                            }}
                            *
                          </p>
                          <input
                            v-model="form.salutationCustom"
                            @input="error = false"
                            :class="[
                              'input formelement',
                              {
                                'is-danger':
                                  showValidation &&
                                  !form.salutationCustom.trim(),
                              },
                            ]"
                            type="text"
                            autocomplete="honorific-prefix"
                            :placeholder="
                              $t(
                                'views.register.account.form.salutation.headline-other'
                              )
                            "
                          />
                        </template>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.name") }} *
                        </p>
                        <input
                          v-model="form.name"
                          @input="error = false"
                          :class="[
                            'input formelement',
                            {
                              'is-danger': showValidation && !form.name.trim(),
                            },
                          ]"
                          type="text"
                          autocomplete="given-name"
                          :placeholder="$t('views.register.account.form.name')"
                        />
                      </div>
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.surname") }} *
                        </p>
                        <input
                          v-model="form.surname"
                          @input="error = false"
                          :class="[
                            'input formelement',
                            {
                              'is-danger':
                                showValidation && !form.surname.trim(),
                            },
                          ]"
                          type="text"
                          autocomplete="family-name"
                          :placeholder="
                            $t('views.register.account.form.surname')
                          "
                        />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.country") }} *
                        </p>
                        <button
                          :class="[
                            'button is-fullwidth is-justify-content-space-between formelement',
                            {
                              'is-danger': showValidation && !selectedPartner,
                            },
                          ]"
                          style="height: unset"
                          @click.stop="
                            () => {
                              error = false;
                              eventBus.$emit('open-country-select');
                            }
                          "
                        >
                          <div
                            class="is-flex is-flex-grow-1 is-flex-direction-column is-align-items-flex-start has-text-left"
                            style="white-space: normal"
                          >
                            <span
                              :class="
                                selectedPartner
                                  ? 'has-text-weight-bold'
                                  : 'has-text-grey-light'
                              "
                            >
                              <span v-if="selectedPartner">
                                <template
                                  v-if="selectedPartner.AppCountry.countryData"
                                >
                                  {{
                                    selectedPartner.AppCountry.countryData.flag
                                  }}
                                </template>
                                {{
                                  translations[
                                    selectedPartner.AppCountry.country_code
                                  ]
                                }}
                              </span>
                              <span v-else>
                                {{
                                  $t(
                                    "views.register.account.form.country-fallback"
                                  )
                                }}
                              </span>
                            </span>
                            <small
                              v-if="selectedPartner && selectedPartner.company"
                            >
                              {{ selectedPartner.company }}
                            </small>
                          </div>
                          <span class="icon is-small">
                            <fa icon="chevron-down" class="fa-fw" />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.email") }} *
                        </p>
                        <input
                          v-model="form.email"
                          @input="error = false"
                          :class="[
                            'input formelement',
                            {
                              'is-danger': showValidation && !validMail,
                            },
                          ]"
                          type="e-mail"
                          autocomplete="email"
                          :placeholder="$t('views.register.account.form.email')"
                        />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.company") }} *
                        </p>
                        <input
                          v-model="form.company"
                          @input="error = false"
                          :class="[
                            'input formelement',
                            {
                              'is-danger':
                                showValidation && !form.company.trim(),
                            },
                          ]"
                          type="text"
                          autocomplete="organization"
                          :placeholder="
                            $t('views.register.account.form.company')
                          "
                        />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.granitid") }} *
                        </p>
                        <input
                          v-model="form.granitid"
                          @input="error = false"
                          :class="[
                            'input formelement',
                            {
                              'is-danger':
                                (showValidation && !form.granitid.trim()) ||
                                form.granitid.toLowerCase().startsWith('w'),
                            },
                          ]"
                          type="text"
                          autocomplete="off"
                          :placeholder="
                            $t('views.register.account.form.granitid')
                          "
                        />
                        <p
                          class="has-text-danger mt-2"
                          v-if="
                            form.granitid.trim().toLowerCase().startsWith('w')
                          "
                        >
                          {{
                            $t("views.register.account.form.granitid-warning")
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("views.register.account.form.promo") }}
                        </p>
                        <div class="field has-addons">
                          <div class="control has-icons-right is-expanded">
                            <input
                              v-model="couponInput"
                              @input="
                                () => {
                                  error = false;
                                  couponValid = null;
                                  couponInput = couponInput.toUpperCase();
                                }
                              "
                              @keyup.enter="couponInput.trim() && addCoupon()"
                              :class="[
                                'input formelement',
                                {
                                  'is-danger':
                                    couponValid === false ||
                                    (showValidation &&
                                      !form.coupon &&
                                      shouldActivateCoupon &&
                                      !couponWarningIgnored),
                                },
                              ]"
                              type="text"
                              autocomplete="off"
                              :disabled="form.coupon !== null"
                              :placeholder="
                                $t('views.register.account.form.promo')
                              "
                            />
                            <span class="icon is-small is-right">
                              <button
                                v-if="form.coupon !== null"
                                @click="removeCoupon()"
                                class="button is-small is-dark"
                                :disabled="loading"
                                style="
                                  pointer-events: all;
                                  border-radius: 2px !important;
                                  margin-right: 3px;
                                "
                              >
                                <fa icon="times" />
                              </button>
                            </span>
                          </div>
                          <p class="control mb-0" v-if="form.coupon === null">
                            <button
                              :class="[
                                'button',
                                { 'is-primary': couponInput.trim().length },
                                { 'is-loading': couponLoading },
                              ]"
                              @click="addCoupon()"
                              :disabled="
                                !couponInput.trim().length ||
                                couponLoading ||
                                loading
                              "
                            >
                              {{ $t("views.register.account.form.promo-add") }}
                            </button>
                          </p>
                        </div>
                        <div
                          v-if="couponValid === false"
                          class="has-text-danger mt-2"
                        >
                          <strong class="has-text-danger-dark">
                            {{
                              $t("views.voucher.form.voucher.invalid.headline")
                            }}
                          </strong>
                          <br />
                          <span v-if="!couponError" class="has-text-danger">
                            {{
                              $t(
                                "views.voucher.form.voucher.invalid.error:generic"
                              )
                            }}
                          </span>
                          <p v-else class="has-text-danger">
                            {{
                              $t(
                                `views.voucher.form.voucher.invalid.error:${
                                  couponError.message || "generic"
                                }`
                              )
                            }}
                            <br />
                            <small class="has-text-danger-dark">
                              {{
                                $t(
                                  "views.voucher.form.voucher.invalid.error-code"
                                )
                              }}
                              <code
                                style="user-select: all"
                                class="has-text-danger-dark has-text-weight-bold"
                              >
                                {{ couponError.code }}
                              </code>
                            </small>
                          </p>
                        </div>
                        <div
                          v-if="couponValid === true"
                          class="has-text-primary mt-2"
                        >
                          <strong>
                            <fa icon="check" />
                            {{
                              $t("views.voucher.form.voucher.valid.headline")
                            }}
                            <span v-if="couponInfo">
                              "{{
                                $t(
                                  `marketing-campaigns.${couponInfo.i18n_key}`
                                )
                              }}"
                            </span>
                          </strong>
                          <ul class="has-repguide-bullets" v-if="couponInfo">
                            <li
                              class="has-text-primary-dark has-text-weight-semibold"
                              v-for="offer in couponInfo.MarketingOffers"
                              :key="offer.id"
                            >
                              {{ $t(`marketing-offers.${offer.offer_key}`) }}
                            </li>
                          </ul>
                        </div>
                        <div v-else-if="showValidation && shouldActivateCoupon">
                          <p class="has-text-danger mt-5">
                            {{
                              $t("views.register.account.form.voucher-required")
                            }}
                          </p>
                          <div class="is-flex is-justify-content-end">
                            <button
                              v-if="!couponWarningIgnored"
                              class="button is-dark"
                              @click="couponWarningIgnored = true"
                            >
                              {{
                                $t("views.register.account.form.voucher-ignore")
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <label class="mt-4 is-mobile columns">
                      <div class="column is-narrow">
                        <input
                          v-model="form.agb"
                          :class="[
                            'checkbox',
                            {
                              'is-danger': showValidation && !form.agb,
                            },
                          ]"
                          type="checkbox"
                          style="height: 25px; width: 25px"
                        />
                      </div>
                      <p class="column" style="word-break: break-word">
                        <i18n path="views.register.account.form.terms.text">
                          <a
                            :href="
                              generatePartnerLink(
                                selectedPartner,
                                selectedPartner && selectedPartner.terms
                                  ? selectedPartner.terms
                                  : '/terms'
                              )
                            "
                            target="_blank"
                            >{{
                              $t("views.register.account.form.terms.terms")
                            }}</a
                          >
                          <span>{{
                            selectedPartner && selectedPartner.company
                              ? selectedPartner.company
                              : "Willhelm Fricke SE"
                          }}</span>
                          <a
                            :href="
                              generatePartnerLink(
                                selectedPartner,
                                selectedPartner && selectedPartner.privacy
                                  ? selectedPartner.privacy
                                  : '/privacy'
                              )
                            "
                            target="_blank"
                            >{{
                              $t("views.register.account.form.terms.privacy")
                            }}</a
                          >
                        </i18n>
                      </p>
                    </label>

                    <div v-if="error" class="notification is-danger">
                      <div class="columns is-mobile">
                        <div class="column is-narrow pl-4">
                          <fa icon="exclamation" class="fa-2x icon" />
                        </div>
                        <div class="column">
                          <p>
                            <strong>{{
                              $t(
                                "views.register.account.form.error.manual.headline"
                              )
                            }}</strong>
                            <br />
                            {{
                              $t(
                                "views.register.account.form.error.manual.description"
                              )
                            }}
                            <br />
                            <a
                              class="mt-2 button is-primary"
                              style="height: unset !important"
                              :href="generateMailTo()"
                            >
                              <i18n
                                path="views.register.account.form.error.manual.cta"
                              >
                                <br class="is-hidden-tablet" />
                              </i18n>
                            </a>
                          </p>
                          <hr class="my-4 has-background-grey-lighter" />
                          <p>
                            <strong>{{
                              $t(
                                "views.register.account.form.error.returning.headline"
                              )
                            }}</strong>
                            <br />
                            {{
                              $t(
                                "views.register.account.form.error.returning.description"
                              )
                            }}
                            <br />
                            <span class="is-flex is-flex-direction-column mt-2">
                              <router-link
                                :class="[
                                  'button',
                                  { 'is-dark': form.coupon || couponInput },
                                ]"
                                style="
                                  height: unset !important;
                                  white-space: normal !important;
                                "
                                :to="generateReturnVoucherLink()"
                              >
                                <i18n
                                  path="views.register.account.form.error.returning.cta-voucher"
                                >
                                  <br class="is-hidden-tablet" />
                                </i18n>
                              </router-link>
                              <a
                                class="has-text-centered is-fullwidth mt-2 py-2"
                                style="
                                  height: unset !important;
                                  white-space: normal !important;
                                "
                                :href="generateReturnEmailLink()"
                              >
                                <i18n
                                  path="views.register.account.form.error.returning.cta-email"
                                >
                                  <br class="is-hidden-tablet" />
                                </i18n>
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div v-else class="mt-5">
                      <button
                        @click="register()"
                        id="register-button"
                        class="button is-medium is-primary is-flex-direction-column is-fullwidth px-2"
                        style="height: unset !important"
                        :disabled="
                          (showValidation && invalid) ||
                          form.granitid.toLowerCase().startsWith('w') ||
                          loading
                        "
                      >
                        <strong class="is-size-4 is-size-6-mobile">{{
                          $t("views.register.account.form.cta.headline")
                        }}</strong>
                        <small class="is-size-6 is-size-7-mobile">
                          <i18n path="views.register.account.form.cta.text">
                            <br />
                          </i18n>
                        </small>
                      </button>
                    </div>

                    <div
                      class="has-text-centered mx-auto"
                      style="
                        color: hsl(141, 71%, 70%);
                        text-wrap: balance;
                        max-width: 70%;
                      "
                    >
                      <small class="has-text-grey is-inline-block mt-4 mb-3">
                        {{ $t("views.register.footnote") }}
                      </small>
                      <br />
                      <fa icon="lock" class="fa-lg icon" />
                    </div>

                    <div
                      v-if="
                        (!selectedPartner ||
                          selectedPartner.Pricings.length > 1) &&
                        !pricing
                      "
                      style="
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        right: 6px;
                        bottom: 6px;
                        background: rgba(255, 255, 255, 0.75);
                        z-index: 100;
                        backdrop-filter: blur(3px);
                      "
                    >
                      <div class="px-4 py-6 has-text-centered">
                        <div
                          class="has-text-grey-light has-background-white-ter mx-auto mb-4"
                          style="
                            font-size: 3rem;
                            width: 5rem;
                            height: 5rem;
                            border-radius: 999px;
                            display: grid;
                            place-items: center;
                          "
                        >
                          <fa
                            icon="arrow-right"
                            style="transform: rotate(-90deg)"
                          />
                        </div>

                        <strong class="is-size-5 has-text-weight-bold">
                          {{
                            $t(
                              "views.register.account.contract-missing.headline"
                            )
                          }}
                        </strong>
                        <p class="has-text-grey">
                          {{
                            $t(
                              "views.register.account.contract-missing.description"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="column is-hidden-touch is-flex is-align-items-center is-justify-content-center"
                >
                  <div
                    style="
                      z-index: -1;
                      opacity: 0.3;
                      position: absolute;
                      top: 3rem;
                      bottom: 3rem;
                      right: -100vw;
                      left: 2rem;
                      overflow: hidden;
                    "
                  >
                    <img
                      :src="moodImage.src"
                      :style="{
                        position: 'absolute',
                        top: '50%',
                        left: `${moodImage.offset}%`,
                        transform: 'translateY(-50%)',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footerNavigation />
    <SignatureModal
      ref="signatureModal"
      @save="(signature) => (form.signature = signature)"
    />
  </div>
</template>

<script>
import { createClient, gql } from "@urql/core";
import footerNavigation from "../components/footerNavigation.vue";
import LanguageSelect from "../components/language-select.vue";
import { store } from "../store";
import { eventBus } from "../event-bus";
import { generateSearchParams } from "../utm";
import SignatureModal from "../components/signature-modal.vue";
import { AGRICULTURE, CONSTRUCTION } from "../illustrations";

const qlClient = createClient({
  url: "https://rg-api-yoga-a4b3749-rvuvxilsia-ez.a.run.app",
  requestPolicy: "network-only",
  fetchOptions: () => {
    return { headers: {} };
  },
});

export default {
  name: "register-page",
  components: {
    footerNavigation,
    LanguageSelect,
    SignatureModal,
  },
  data() {
    return {
      eventBus,
      loading: false,
      error: false,
      showValidation: false,
      couponInput:
        this.$router.currentRoute.query?.voucher?.toUpperCase() || "",
      couponValid: null,
      couponLoading: false,
      couponError: null,
      couponInfo: null,
      couponWarningIgnored: false,
      pricing: null,
      form: {
        salutation: "",
        salutationCustom: "",
        name: "",
        surname: "",
        // country managed by store
        email: "",
        company: "",
        granitid: "",
        coupon: null,
        signature: null,
        agb: false,
        tracking: {},
      },
    };
  },
  methods: {
    async addCoupon() {
      this.couponInput = this.couponInput.trim().toUpperCase();
      this.couponLoading = true;
      this.couponValid = null;

      const VOUCHER_DATA = {
        contractPartnerId: this.selectedPartner.id,
      };

      if (this.form.granitid?.trim())
        VOUCHER_DATA.granitId = this.form.granitid?.trim();

      this.couponValid = await qlClient
        .query(
          gql`
            query ($pCode: String!, $pData: JSON) {
              VoucherCheckValidity(code: $pCode, data: $pData) {
                valid_until
                is_valid
                error_code
              }
              MarketingCampaign(code: $pCode) {
                i18n_key
                MarketingOffers {
                  id
                  offer_key
                }
              }
            }
          `,
          {
            pCode: this.couponInput,
            pData: VOUCHER_DATA,
          }
        )
        .toPromise()
        .then((result) => {
          this.couponError = result.data?.VoucherCheckValidity?.error_code;
          this.couponInfo = result.data?.MarketingCampaign;
          return result.data?.VoucherCheckValidity?.is_valid;
        });

      if (this.couponValid) this.form.coupon = this.couponInput;
      this.couponLoading = false;
    },
    removeCoupon(coupon) {
      this.form.coupon = null;
      this.couponInput = "";
      this.couponValid = null;
      this.couponError = null;
      this.couponInfo = null;
    },
    generateMailTo() {
      const MAILTO =
        this.selectedPartner?.support_contact || "repguide@fricke.de";
      const SUBJECT = encodeURIComponent(
        this.$t("views.register.account.form.error.manual.email.subject")
      );
      const BODY = encodeURIComponent(
        this.$t("views.register.account.form.error.manual.email.body", {
          salutation: this.form.salutation,
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.email,
          company: this.form.company,
          granitid: this.form.granitid,
          voucher: this.form.voucher || this.couponInput,
        })
      );

      return `mailto:${MAILTO}?subject=${SUBJECT}&body=${BODY}`;
    },
    generateReturnEmailLink() {
      const MAILTO =
        this.selectedPartner?.support_contact || "repguide@fricke.de";
      const SUBJECT = encodeURIComponent(
        this.$t("views.register.account.form.error.returning.email.subject")
      );
      const BODY = encodeURIComponent(
        this.$t("views.register.account.form.error.returning.email.body", {
          salutation: this.form.salutation,
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.email,
          company: this.form.company,
          granitid: this.form.granitid,
          voucher: this.form.voucher || this.couponInput,
        })
      );

      return `mailto:${MAILTO}?subject=${SUBJECT}&body=${BODY}`;
    },
    generateReturnVoucherLink() {
      const BASE_URL = `/${this.$route.params.locale}/voucher`;

      const PARAMS = generateSearchParams({
        code: this.form.coupon || this.couponInput,
        granitid: this.form.granitid,
      });

      return `${BASE_URL}${PARAMS}`;
    },
    generatePartnerLink(partner, pathOrURL) {
      if (pathOrURL.startsWith("http")) {
        return pathOrURL;
      } else {
        const ORIGIN = `https://repguide.${partner?.custom_tld || "eu"}`;
        return `${ORIGIN}/${this.$route.params.locale}${
          pathOrURL.startsWith("/") ? pathOrURL : `/${pathOrURL}`
        }`;
      }
    },
    register() {
      this.loading = true;
      this.showValidation = true;

      if (!this.invalid) {
        const LOGIN = `${this.form.name
          .toLowerCase()
          .trim()}.${this.form.surname.toLowerCase().trim()}`;

        const currentLocale = store.AVAILABLE_LOCALES.find(
          (l) => l.code === this.$root.$i18n.locale
        );

        const userObject = {
          login: LOGIN,
          email: this.form.email.toLowerCase().trim(),
          salutation: this.sex.salutation.trim(),
          firstName: this.form.name.trim(),
          lastName: this.form.surname.trim(),
          sex: this.sex.sex,
          status: true,
          password: null,
          userRoles: "{1}",
          granitid: this.form.granitid.trim(),
          company: this.form.company.trim(),
          voucherCodes: this.form.coupon ? [this.form.coupon] : null,
          tier: this.selectedPricing.Pricing.tier.trim(),
          country: this.selectedPartner.AppCountry.country_code.trim(),
          contract_partner_default: this.selectedPartner.id,
          granit_shop_default: this.selectedPartner.granit_shop_default,
          tracking: Object.keys(this.form.tracking)?.length
            ? this.form.tracking
            : null,
          language: currentLocale,
          // has to be refactored if we use different pricings on same contract partner. Should then go for selected pricing if selected.
        };

        const workflowObject = {
          login: userObject.login,
          email: userObject.email,
          salutation: userObject.salutation,
          prename: userObject.firstName,
          name: userObject.lastName,
          sex: userObject.sex,
          status: userObject.status,
          granitid: userObject.granitid,
          company: userObject.company,
          voucherCodes: userObject.voucherCodes,
          tier: userObject.tier,
          country: userObject.country,
          user_roles: "{1}",
          contract_partner_default: parseInt(
            userObject.contract_partner_default
          ),
          granit_shop_default: parseInt(userObject.granit_shop_default),
          tracking: userObject.tracking,
          language: parseInt(userObject.language.appLanguageId),
        };

        if (this.form.signature) {
          workflowObject.signature = this.form.signature;
        }

        if (this.selectedPricing) {
          workflowObject.base_pricing = parseInt(
            this.selectedPricing.Pricing.id
          );
        } else {
          workflowObject.base_pricing = null;
        }

        if (
          this.shouldActivateCoupon
            ? // check for valid voucher or confirm missing voucher
              workflowObject.voucherCodes?.length || this.couponWarningIgnored
            : // check for valid voucher or confirm pending voucher
              workflowObject.voucherCodes?.length ||
              !this.couponInput.trim() ||
              this.couponValid === false ||
              window.confirm(this.translations.PROMO_PENDING)
        ) {
          qlClient
            .mutation(
              gql`
                mutation T($data: CustomerInput) {
                  RegisterCustomer(data: $data)
                }
              `,
              { data: workflowObject }
            )
            .toPromise()
            .then((result) => {
              if (result.data?.RegisterCustomer) {
                const query = {
                  ...this.$router.currentRoute.query,
                  name: `${userObject.salutation} ${userObject.lastName}`,
                  email: workflowObject.email,
                };
                this.$router.push({ name: "registered", query });
              } else {
                // NOTE: email or granitid already occupied by another user or company
                this.error = true;
              }
            });
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    getMonth(duration) {
      // NOTE: extracting month from PSQL duration
      return duration?.match(/(\d+)M/)?.[1] || null;
    },
    getCurrencyDisplay(currency, amount) {
      const FORMAT = new Intl.NumberFormat(this.$root.$i18n.locale, {
        style: "currency",
        currency: currency,
      });

      return FORMAT.format(amount);
    },
    getInvoiceItemDisplay(invoiceItems) {
      const item = invoiceItems.find(
        (i) => i.countryId === this.selectedPartner.AppCountry.id
      );
      return this.getCurrencyDisplay(item.itemCurrency, item.itemPrice);
    },
    getPricingStatistics(p) {
      let statistics = [
        {
          title: this.$t("views.register.contract.statistics.agricultural"),
          count: this.$t("views.register.contract.statistics.over", {
            count: store.totalAgricultureCount,
          }),
        },
        {
          title: this.$t("views.register.contract.statistics.engines"),
          count: this.$t("views.register.contract.statistics.over", {
            count: store.totalEnginesCount,
          }),
        },
      ];

      if (p.Pricing.contract_key === "MULTI-INDUSTRY") {
        statistics.push({
          title: this.$t("views.register.contract.statistics.construction"),
          count: this.$t("views.register.contract.statistics.over", {
            count: store.totalConstructionCount,
          }),
        });
      }

      return statistics;
    },
    getPricingFeatures() {
      const HAS_HOTLINE = ["DE", "AT", "CH"];

      let features = [
        {
          title: this.$t("views.register.contract.features.fleet.title"),
          desc: this.$t("views.register.contract.features.fleet.desc"),
          icon: "warehouse",
        },
        {
          title: this.$t("views.register.contract.features.storage.title"),
          desc: this.$t("views.register.contract.features.storage.desc"),
          icon: "database",
        },
        {
          title: this.$t("views.register.contract.features.support.title"),
          desc: this.$t("views.register.contract.features.support.desc"),
          icon: "phone",
        },
        {
          title: this.$t("views.register.contract.features.special.title"),
          desc: this.$t("views.register.contract.features.special.desc"),
          icon: "th-large",
        },
        {
          title: this.$t("views.register.contract.features.jaltest.title"),
          desc: this.$t("views.register.contract.features.jaltest.desc"),
          icon: ["rg", "jaltest"],
        },
      ];

      if (HAS_HOTLINE.includes(this.selectedPartner.AppCountry.country_code)) {
        features[2].title = this.$t(
          "views.register.contract.features.support.title2"
        );
      }

      return features;
    },
    getPricingBenefits(pricing) {
      return [
        {
          title: this.$t("views.register.contract.benefits.access"),
          icon: "globe",
        },
        {
          title: this.$t("views.register.contract.benefits.install"),
          icon: "mobile",
        },
        {
          title:
            pricing.Pricing.contract_key === "MULTI-INDUSTRY"
              ? this.$t("views.register.contract.benefits.login2")
              : this.$t("views.register.contract.benefits.login"),
          icon: "users",
        },
        {
          title: this.$t("views.register.contract.benefits.key.title"),
          desc: this.$t("views.register.contract.benefits.key.desc"),
          icon: "industry",
        },
      ];
    },
    getPricingConditions(pricing) {
      const bullets = [];

      if (!pricing) return bullets;

      if (pricing.Pricing.PricingOffer?.pricing_key) {
        let meta = undefined;
        let description = [
          this.$t(
            `views.register.offer.${pricing.Pricing.PricingOffer.pricing_key}.description`
          ),
        ];
        if (pricing.Pricing.PricingOffer.valid_until) {
          meta = this.$t(`views.register.offer.valid_until`, {
            0: new Date(
              pricing.Pricing.PricingOffer.valid_until
            ).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
          });
        }
        bullets.push({
          highlight: true,
          title: this.$t(
            `views.register.offer.${pricing.Pricing.PricingOffer.pricing_key}.headline`
          ),
          description,
          meta,
        });
      }
      bullets.push(
        {
          title: this.$tc(
            "views.register.contract.conditions.test.title",
            this.getMonth(this.selectedPartner?.Pricing?.period_duration)
          ),
          description: [
            this.$tc(
              "views.register.contract.conditions.test.desc",
              this.getMonth(this.selectedPartner?.Pricing?.period_duration)
            ),
            this.$t("views.register.contract.conditions.test.desc2"),
          ],
        },
        {
          title: this.$t("views.register.contract.conditions.contract.title"),
          description: [
            this.$t("views.register.contract.conditions.contract.desc"),
            this.$t("views.register.contract.conditions.contract.desc2"),
            this.$t("views.register.contract.conditions.contract.desc3"),
          ],
        }
      );

      return bullets;
    },
  },
  mounted() {
    const PARAMS = this.$route.query;

    this.form.salutation = PARAMS?.salutation
      ? PARAMS?.salutation.toLowerCase() == "male"
        ? "mr"
        : "ms"
      : "";
    this.form.name = PARAMS?.prename || "";
    this.form.surname = PARAMS?.name || "";
    this.form.email = PARAMS?.email || "";
    this.form.company = PARAMS?.company || "";
    this.form.granitid = PARAMS?.granitId || "";
    if (PARAMS?.utm_source) this.form.tracking.utm_source = PARAMS?.utm_source;
    if (PARAMS?.utm_medium) this.form.tracking.utm_medium = PARAMS?.utm_medium;
    if (PARAMS?.utm_campaign)
      this.form.tracking.utm_campaign = PARAMS?.utm_campaign;
    if (PARAMS?.utm_content)
      this.form.tracking.utm_content = PARAMS?.utm_content;
    if (PARAMS?.utm_term) this.form.tracking.utm_term = PARAMS?.utm_term;
  },
  computed: {
    contractPartnersLoading() {
      return store.loading;
    },
    selectedPricing() {
      if (this.pricing) return this.pricing;
      const DEFAULT = this.selectedPartner?.Pricings.find(
        (p) => p.is_default
      )?.Pricing;
      return DEFAULT || this.selectedPartner?.Pricings[0].Pricing;
    },
    availablePricings() {
      if (!this.selectedPartner) return [];
      return (this.selectedPartner?.Pricings || []).sort(
        (a, b) => b.is_default - a.is_default
      );
    },
    shouldActivateCoupon() {
      const CAMPAIGNS_WITH_VOUCHERS = ["100years"];
      return CAMPAIGNS_WITH_VOUCHERS.includes(this.form.tracking?.utm_campaign);
    },
    salutations() {
      return [
        {
          label: this.$t("views.register.account.form.salutation.mr"),
          value: "mr",
        },
        {
          label: this.$t("views.register.account.form.salutation.ms"),
          value: "ms",
        },
        {
          label: this.$t("views.register.account.form.salutation.other"),
          value: "other",
        },
      ];
    },
    selectedPartner() {
      return store.selectedPartner;
    },
    loadingPartners() {
      return store.loading;
    },
    moodImage() {
      const CONSTRUCTION_PRICINGS = ["MULTI-INDUSTRY"];

      if (
        CONSTRUCTION_PRICINGS.includes(
          this.selectedPricing?.Pricing?.contract_key
        )
      )
        return CONSTRUCTION.at(Math.floor(Math.random() * CONSTRUCTION.length));
      else
        return AGRICULTURE.at(Math.floor(Math.random() * AGRICULTURE.length));
    },
    invalid() {
      return !(
        (this.form.salutationCustom?.trim() ||
          this.form.salutation !== "other") &&
        (this.pricing || this.selectedPartner.Pricings.length === 1) &&
        this.form.name?.trim() &&
        this.form.surname?.trim() &&
        this.selectedPartner?.AppCountry?.country_code?.trim() &&
        this.validMail &&
        this.form.company?.trim() &&
        this.form.granitid?.trim() &&
        !this.form.granitid.toLowerCase().startsWith("w") &&
        (this.form.coupon ||
          !this.shouldActivateCoupon ||
          this.couponWarningIgnored) &&
        this.form.agb
      );
    },
    sex() {
      const SELECTED_SALUTATION = this.salutations.find(
        (s) => s.value === this.form.salutation
      );

      const SALUTATION =
        SELECTED_SALUTATION?.value === "other"
          ? this.form.salutationCustom
          : SELECTED_SALUTATION?.label || "";

      const M_LABEL = this.salutations.find((s) => s.value === "mr").label;
      const W_LABEL = this.salutations.find((s) => s.value === "ms").label;

      let sex = "u";
      if (SALUTATION.toLowerCase().includes(M_LABEL.toLowerCase())) sex = "m";
      if (SALUTATION.toLowerCase().includes(W_LABEL.toLowerCase())) sex = "w";

      return { salutation: SALUTATION, sex };
    },
    validMail() {
      // NOTE: https://emailregex.com/
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.form.email
      );
    },
    isValidSalutation(salutation) {
      return this.salutations.includes(salutation);
    },
    translations() {
      return {
        AT: this.$t("components.countrySelect.countries.AT"),
        BE: this.$t("components.countrySelect.countries.BE"),
        BA: this.$t("components.countrySelect.countries.BA"),
        BG: this.$t("components.countrySelect.countries.BG"),
        HR: this.$t("components.countrySelect.countries.HR"),
        CZ: this.$t("components.countrySelect.countries.CZ"),
        DK: this.$t("components.countrySelect.countries.DK"),
        GB: this.$t("components.countrySelect.countries.GB"),
        EE: this.$t("components.countrySelect.countries.EE"),
        FI: this.$t("components.countrySelect.countries.FI"),
        FR: this.$t("components.countrySelect.countries.FR"),
        DE: this.$t("components.countrySelect.countries.DE"),
        HU: this.$t("components.countrySelect.countries.HU"),
        IE: this.$t("components.countrySelect.countries.IE"),
        IT: this.$t("components.countrySelect.countries.IT"),
        LV: this.$t("components.countrySelect.countries.LV"),
        LT: this.$t("components.countrySelect.countries.LT"),
        LU: this.$t("components.countrySelect.countries.LU"),
        NL: this.$t("components.countrySelect.countries.NL"),
        NO: this.$t("components.countrySelect.countries.NO"),
        PL: this.$t("components.countrySelect.countries.PL"),
        PT: this.$t("components.countrySelect.countries.PT"),
        RO: this.$t("components.countrySelect.countries.RO"),
        RS: this.$t("components.countrySelect.countries.RS"),
        SK: this.$t("components.countrySelect.countries.SK"),
        SI: this.$t("components.countrySelect.countries.SI"),
        ES: this.$t("components.countrySelect.countries.ES"),
        SE: this.$t("components.countrySelect.countries.SE"),
        CH: this.$t("components.countrySelect.countries.CH"),
        UA: this.$t("components.countrySelect.countries.UA"),
        US: this.$t("components.countrySelect.countries.US"),
        CA: this.$t("components.countrySelect.countries.CA"),
        PROMO_PENDING: this.$t("views.register.account.form.promo-pending"),
        VOUCHER_REQUIRED: this.$t(
          "views.register.account.form.voucher-required"
        ),
      };
    },
  },
};
</script>

<style lang="scss">
ul.has-repguide-bullets {
  list-style: none !important;
  margin-left: 0 !important;
  li {
    padding-left: 2.7rem;
    line-height: 1.7rem;
    position: relative;
  }
  li:before {
    content: "";
    display: block;
    position: absolute;
    height: 24px;
    width: 16px;
    background: #e94a10;
    top: 0.1rem;
    left: 0.75rem;
    transform: skewX(-12.56deg);
  }
}

.has-text-danger {
  color: #f14668;
}

.has-text-danger-dark {
  color: #5a0f1a;
}

.is-super-custom-for-bulma {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;

  @media screen and (max-width: 1216px) {
    grid-template-columns: 1fr;
    div:nth-of-type(3) {
      grid-row: 2;
    }
  }
}
.is-danger.formelement {
  border-color: #f14668 !important;
  &:focus {
    box-shadow: 0 0 0 0.125em rgb(241 70 104 / 25%) !important;
  }
}
.is-danger.checkbox {
  outline: 1px solid #f14668 !important;
  &:focus {
    box-shadow: 0 0 0 0.125em rgb(241 70 104 / 25%) !important;
  }
}

.why-do-i-need-custom-styles-for-this {
  gap: 8px;
  > .is-flex {
    gap: 8px;
  }
  @media screen and (max-width: 769px) {
    flex-direction: column;
    a,
    .select,
    select {
      width: 100%;
    }
  }
}

.please-let-me-use-tailwind {
  border-color: #e94a10 !important;
}

.highlight-links a {
  color: #e94a10;
}

.animate-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.contract-bullet:hover {
  .contract-bullet-tooltip {
    opacity: 1 !important;
  }
}

.contract-bullet-tooltip {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  z-index: 100;
  bottom: calc(100% + 0.5rem);
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 0.5rem rgba(0, 0, 0, 0.075);
  right: -0.5rem;
  left: -0.5rem;
  border-radius: 0.25rem;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0.5rem;
    border-color: #ddd transparent transparent transparent;
    right: 0.625rem;
    bottom: -0.5rem;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.45rem 0.45rem 0 0.45rem;
    border-color: white transparent transparent transparent;
    right: 0.655rem;
    bottom: -0.4rem;
  }
}
</style>
