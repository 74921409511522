<template>
  <nav
    :class="[
      'navbar',
      'is-spaced',
      'is-fixed-top',
      { 'is-transparent': navtransparent },
    ]"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link :to="'/' + $route.params.locale + '/'">
          <img
            v-if="navtransparent"
            alt="Rep.Guide Logo White"
            src="../assets/RepGuideLogoWhite.svg"
            width="160"
            class="is-hidden-mobile"
          />
          <img
            v-else
            alt="Rep.Guide Logo"
            src="../assets/RepGuideLogo.svg"
            width="160"
            class="is-hidden-mobile"
          />
          <img
            alt="Rep.Guide Logo"
            src="../assets/RepGuideLogoNoText.svg"
            width="68"
            class="is-hidden-tablet"
            style="min-width: 68px; max-width: 68px"
          />
        </router-link>

        <div v-if="landingpage" class="navbar-item ml-auto is-hidden-desktop">
          <a href="#landingpage-target" class="button is-primary">
            {{ $t("views.discover.email-signup.cta-short") }}
          </a>
        </div>

        <div
          :class="[
            'navbar-item',
            'is-hidden-desktop',
            { 'ml-auto': !landingpage },
          ]"
        >
          <language-select />
        </div>

        <a
          v-if="!landingpage"
          role="button"
          :class="[
            'navbar-burger burger',
            'is-hidden-desktop',
            'ml-0',
            { 'has-text-white': navtransparent },
          ]"
          aria-label="menu"
          @click="isOpen = !isOpen"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div :class="['navbar-menu', { 'is-active': isOpen }]">
        <div v-if="!landingpage" class="navbar-start">
          <router-link
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/inhalte'"
          >
            {{ $t("components.navigation.links.features") }}
          </router-link>

          <router-link
            v-if="hasHotlineAccess"
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/hotline'"
          >
            {{ $t("components.navigation.links.hotline") }}
          </router-link>

          <router-link
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              'mr-0',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/wartung'"
          >
            {{ $t("components.navigation.links.service") }}
          </router-link>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a
                v-if="landingpage"
                href="#landingpage-target"
                class="button is-primary is-hidden-touch"
              >
                {{ $t("views.discover.email-signup.cta") }}
              </a>

              <router-link
                v-if="!landingpage"
                @click="isOpen = false"
                class="button is-primary"
                :to="'/' + $route.params.locale + '/register'"
              >
                {{ $t("components.navigation.cta") }}
              </router-link>
              <a
                v-if="!landingpage"
                @click="isOpen = false"
                class="button"
                target="_blank"
                rel="noopener noreferrer"
                :href="getLoginUrl($root.$i18n.locale)"
              >
                {{ $t("components.navigation.login") }}
                <span class="icon ml-2 mr-1">
                  <fa icon="sign-in-alt" class="icon" />
                </span>
              </a>
              <div class="is-hidden-touch">
                <language-select />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSelect from "./language-select.vue";
import { generateSearchParams } from "../utm.js";
import { store } from "../store";

export default {
  components: { LanguageSelect },
  data() {
    return {
      isOpen: false,
      topScroll: this.fancy,
    };
  },
  props: {
    fancy: Boolean,
    landingpage: { type: Boolean, default: false },
    offset: { type: Number, default: 50 },
    utm: { type: Object, default: () => {} },
  },
  mounted() {
    this.topScroll = window.pageYOffset < this.offset;
    window.addEventListener("scroll", () => {
      this.topScroll = window.pageYOffset < this.offset;
    });
  },
  computed: {
    hasHotlineAccess() {
      if (!store.selectedPartner?.AppCountry?.country_code) return false;

      return ["DE", "AT", "CH"].includes(
        store.selectedPartner.AppCountry.country_code
      );
    },
    navtransparent() {
      return this.fancy ? (this.isOpen ? false : this.topScroll) : false;
    },
    utmString() {
      const UTM = { ...this.utm, utm_content: "main_navigation" };
      return generateSearchParams(UTM);
    },
  },
  methods: {
    getLoginUrl(locale) {
      const UTM = { utm_source: "repguide", utm_medium: "marketingseite" };
      return `https://rep.guide/${locale}/login${generateSearchParams(UTM)}`;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  box-shadow: 0 8px 16px rgba(0 0 0 / 0.2);
  &.is-transparent {
    box-shadow: none;
    background: transparent !important;
  }
}
.navbar,
.navbar-item {
  transition: all 0.2s ease;
}

.navbar .navbar-brand a img {
  height: 100%;
  padding: 12px 1rem;
  @media screen and (min-width: 1024px) {
    padding-top: 0;
  }
}

.navbar a.navbar-item {
  position: relative;
  transition: none;
  margin-right: 0.25rem;
}
.navbar a.navbar-item.router-link-active {
  color: #e94a10;
  background: #e94a1022;
}

.navbar .navbar-placeholder {
  transform: skewX(-12.56deg);
}

@media (min-width: 1024px) {
  .navbar a.navbar-item:hover {
    color: white !important;
    background: none;
    transition: color 0.2s 0.1s ease;
  }

  .navbar a.navbar-item:hover::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2.5rem;
    background: #e94a10;
    z-index: -10;
    transform: skewX(-12.56deg);
    -webkit-animation: slide 0.2s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slide 0.2s forwards;
    animation-delay: 0.1s;
  }
}
@-webkit-keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
