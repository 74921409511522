export const CONSTRUCTION = [
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Liebherr-A-Serie-(Gen-60-D).png",
    offset: -5,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Komatsu-PC-7.png",
    offset: -5,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Kubota-KX.png",
    offset: 20,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Takeuchi-TB.png",
    offset: 12,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Komatsu-WA-8.png",
    offset: -5,
  },
];

export const AGRICULTURE = [
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Fendt-700-Vario-S4.png",
    offset: 5,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Massey-Ferguson-MF-7700.png",
    offset: 5,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__John-Deere-6MC.png",
    offset: 10,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Claas-Axion-900.png",
    offset: 0,
  },
  {
    src: "https://repguide.fra1.digitaloceanspaces.com/_illustrations/Rasterized/Rep.Guide_Illustration__Deutz-Fahr-Agrotron-L.png",
    offset: 5,
  },
];
