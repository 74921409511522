<template>
  <section class="section is-medium" id="news">
    <div class="container">
      <h2 class="title is-size-2 is-size-4-mobile mt-0">
        {{ $t("views.index.news.headline") }}
      </h2>
      <div ref="news" class="columns is-multiline">
        <div
          class="column is-4-widescreen is-half is-flex"
          v-for="article in articles.slice(0, limit ? 3 : Infinity)"
          :key="article.title"
        >
          <article class="card">
            <div class="card-image">
              <figure class="image is-3by2">
                <img v-if="article.image" :src="getImgUrl(article.image)" />
              </figure>
            </div>
            <div class="card-content">
              <p class="mb-2">
                <time :datetime="article.date" class="has-text-primary">
                  {{
                    article.date.toLocaleDateString(undefined, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  }}
                </time>
                <span class="has-text-grey-lighter is-hidden-mobile"> | </span>
                <span
                  v-if="article.tag"
                  class="tag is-primary has-text-weight-bold"
                  style="vertical-align: text-top"
                >
                  {{ article.tag }}
                </span>
                <strong> {{ article.title }}</strong>
              </p>

              <div v-html="article.excert" />

              <p v-if="article.link" class="mt-3">
                <!-- keep using non router-link, since article.linkUrl isn't necessarily internal -->
                <a
                  class="has-text-primary has-text-weight-medium"
                  :href="article.link.url"
                >
                  {{ article.link.text }}
                </a>
              </p>
            </div>
          </article>
        </div>
      </div>
      <p>
        <a v-if="limit" class="has-text-grey" @click.stop="limit = false">
          <fa icon="plus-circle" class="icon icon-text" />
          {{ $t("views.index.news.show-more") }}
        </a>
        <a v-else class="has-text-grey" @click.stop="limit = true">
          <fa icon="minus-circle" class="icon icon-text" />
          {{ $t("views.index.news.show-less") }}
        </a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    getImgUrl(pic) {
      return require("../../assets/inhalte/" + pic);
    },
  },
  data() {
    return {
      limit: true,
    };
  },
  computed: {
    articles() {
      return [
        {
          date: new Date("2025-04-01"),
          image: "construction.jpg",
          title: this.$t("views.index.news.articles.2025-04-01.headline"),
          excert: this.$t("views.index.news.articles.2025-04-01.excert"),
          link: {
            text: this.$t("views.index.news.articles.2025-04-01.link"),
            url: "/register",
          },
        },
        {
          date: new Date("2024-11-01"),
          image: "psw-2024.jpg",
          title: this.$t("views.index.news.articles.2024-11-01.headline"),
          excert: this.$t("views.index.news.articles.2024-11-01.excert"),
        },
        {
          date: new Date("2024-08-21"),
          image: "bosch-rexroth.png",
          title: this.$t("views.index.news.articles.2024-08-21.headline"),
          excert: this.$t("views.index.news.articles.2024-08-21.excert"),
        },
        {
          date: new Date("2024-03-01"),
          image: "engine-repair.jpg",
          title: this.$t("views.index.news.articles.2024-03-01.headline"),
          excert: this.$t("views.index.news.articles.2024-03-01.excert"),
        },
        {
          date: new Date("2024-01-01"),
          image: "holiday-tractor.jpg",
          title: this.$t("views.index.news.articles.2024-01-01.headline"),
          excert: this.$t("views.index.news.articles.2024-01-01.excert"),
        },
        {
          date: new Date("2023-12-01"),
          image: "plowing.jpg",
          title: this.$t("views.index.news.articles.2023-12-01.headline"),
          excert: this.$t("views.index.news.articles.2023-12-01.excert"),
        },
        {
          date: new Date("2023-11-01"),
          image: "fleet.jpg",
          title: this.$t("views.index.news.articles.2023-11-01.headline"),
          excert: this.$t("views.index.news.articles.2023-11-01.excert"),
        },
        {
          date: new Date("2023-10-01"),
          image: "garage-drawer.jpg",
          title: this.$t("views.index.news.articles.2023-10-01.headline"),
          excert: this.$t("views.index.news.articles.2023-10-01.excert"),
        },
        {
          date: new Date("2023-09-01"),
          image: "repair.jpg",
          title: this.$t("views.index.news.articles.2023-09-01.headline"),
          excert: this.$t("views.index.news.articles.2023-09-01.excert"),
        },
        {
          date: new Date("2023-08-01"),
          image: "errors.jpg",
          title: this.$t("views.index.news.articles.2023-08-01.headline"),
          excert: this.$t("views.index.news.articles.2023-08-01.excert"),
        },
        {
          date: new Date("2023-06-01"),
          image: "construction-repair.jpg",
          title: this.$t("views.index.news.articles.2023-06-01.headline"),
          excert: this.$t("views.index.news.articles.2023-06-01.excert"),
        },
        {
          date: new Date("2023-03-01"),
          image: "international-2.jpg",
          title: this.$t("views.index.news.articles.2023-03-01.headline"),
          excert: this.$t("views.index.news.articles.2023-03-01.excert"),
        },
        {
          date: new Date("2023-01-01"),
          image: "illustrations.png",
          title: this.$t("views.index.news.articles.2023-01-01-2.headline"),
          excert: this.$t("views.index.news.articles.2023-01-01-2.excert"),
        },
        {
          date: new Date("2023-01-01"),
          image: "fleet.jpg",
          title: this.$t("views.index.news.articles.2023-01-01.headline"),
          excert: this.$t("views.index.news.articles.2023-01-01.excert"),
        },
        {
          date: new Date("2022-12-01"),
          image: "psw-2022.jpg",
          title: this.$t("views.index.news.articles.2022-12-01-2.headline"),
          excert: this.$t("views.index.news.articles.2022-12-01-2.excert"),
        },
        {
          date: new Date("2022-12-01"),
          image: "education.jpg",
          title: this.$t("views.index.news.articles.2022-12-01.headline"),
          excert: this.$t("views.index.news.articles.2022-12-01.excert"),
          link: {
            text: this.$t("views.index.news.articles.more-details"),
            url: "/education",
          },
        },
        {
          date: new Date("2022-11-01"),
          image: "international.jpg",
          title: this.$t("views.index.news.articles.2022-11-01.headline"),
          excert: this.$t("views.index.news.articles.2022-11-01.excert"),
        },
        {
          date: new Date("2022-10-01"),
          image: "construction-waiting.jpg",
          title: this.$t("views.index.news.articles.2022-10-01.headline"),
          excert: this.$t("views.index.news.articles.2022-10-01.excert"),
        },
        {
          date: new Date("2021-11-25"),
          image: "service.jpg",
          title: this.$t("views.index.news.articles.2021-11-25.headline"),
          excert: this.$t("views.index.news.articles.2021-11-25.excert"),
          link: {
            text: this.$t("views.index.news.articles.more-details"),
            url: "/wartung",
          },
        },
        {
          date: new Date("2021-07-01"),
          image: "diagrams.jpg",
          title: this.$t("views.index.news.articles.2021-07-01.headline"),
          excert: this.$t("views.index.news.articles.2021-07-01.excert"),
        },
        {
          date: new Date("2021-04-13"),
          image: "search.png",
          title: this.$t("views.index.news.articles.2021-04-13.headline"),
          excert: this.$t("views.index.news.articles.2021-04-13.excert"),
        },
        {
          date: new Date("2021-03-31"),
          image: "support.jpg",
          title: this.$t("views.index.news.articles.2021-03-31.headline"),
          excert: this.$t("views.index.news.articles.2021-03-31.excert"),
        },
      ];
    },
  },
};
</script>
